// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { Button, TextField } from "@mui/material";

// 4. Component Imports:

// 7. Assets:
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

// --------------------------------------------------------------

export function TeamManagement({
  account_id,
  additionalLogins,
  createTeamMember,
  deleteTeamMember,
  updateAdditionalLogins,
}) {
  /*-------------------------------------
    State Variables and Hooks
	-------------------------------------*/
  const [teamMembers, setTeamMembers] = useState([]);

  const [showAddTeamMember, setShowAddTeamMember] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState();
  const [newMemberName, setNewMemberName] = useState();

  const [formErrors, setFormErrors] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);


  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/

  const validateForm = () => {
    let formErrors = {};

    if (!newMemberName) {
      formErrors.name = "Team Member name is required.";
    }
    if (!newMemberEmail) {
      formErrors.email = "Team Member email is required.";
    } else if (!/\S+@\S+\.\S+/.test(newMemberEmail)) {
      formErrors.email = "Team Member email is invalid.";
    }

    setFormErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleToggleAddTeamMember = () => {
    setShowAddTeamMember((prevState) => !prevState);
  };

  const handleClickAddTeamMember = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      account_id: account_id,
      login_name: newMemberName,
      email: newMemberEmail,
      login_type: "team member",
    };

    setFormProcessing(true);
    const response = await createTeamMember(data);
    setFormProcessing(false);

    // Check if response is a success (an integer)
    if (Number.isInteger(response)) {
      const newTeamMember = {
        user_id: response, // assuming the integer is the new team member's ID
        email: newMemberEmail,
        login_type: "team member",
        login_name: newMemberName,
      };

      // Update the additionalLogins state with the new team member
      setTeamMembers((prevTeam) => [...prevTeam, newTeamMember]);

      // Update additionalLogins via the parent function
      updateAdditionalLogins([...additionalLogins, newTeamMember]);

      handleToggleAddTeamMember(); // Hide the Add New Team Member form

      // Reset the form fields
      setNewMemberEmail("");
      setNewMemberName("");
    }
  };

  const handleClickDeleteTeamMember = async (user_id) => {
    const data = { id: user_id };
    const response = await deleteTeamMember(data);

    console.log("handleClickDeleteTeamMember", response);

    if(response){
      const newTeamMembers = teamMembers.filter((member) => member.user_id !== user_id);
      setTeamMembers(newTeamMembers);
      updateAdditionalLogins(newTeamMembers);
    }     
  };


  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    if (additionalLogins) {
      setTeamMembers(additionalLogins);
    }
  }, [additionalLogins]);

  return (
    <>
      <div className="mx-auto flex w-full max-w-3xl items-center justify-between pt-10">
        <div className="text-2xl font-bold">Team Management</div>
        <div className="flex items-center ml-auto">
          <Button
            className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
            onClick={handleToggleAddTeamMember}
            startIcon={showAddTeamMember ? null : <AddIcon fontSize="small" />}
          >
            {showAddTeamMember ? "Cancel" : "Add Team Member"}
          </Button>
        </div>
      </div>
      {showAddTeamMember && (
        <div
          id="linkUser"
          className="mx-auto flex w-full flex-col max-w-3xl items-end justify-between mt-3"
        >
          <div className="w-full">
            <div className="text-lg">Add a new Team Member</div>
          </div>
          <div className="mt-2 flex flex-row divide-y w-full">
            <TextField
              label="Name"
              value={newMemberName}
              size="small"
              onChange={(e) => setNewMemberName(e.target.value)}
              error={!!formErrors.name}
              helperText={formErrors.name}
              fullWidth
            />
            <TextField
              className="ml-4"
              label="Email"
              value={newMemberEmail}
              type="email"
              size="small"
              onChange={(e) => setNewMemberEmail(e.target.value)}
              error={!!formErrors.email}
              helperText={formErrors.email}
              fullWidth
            />
            <Button
              type="button"
              variant="contained"
              color="primary"
              className="ml-4"
              disableElevation
              onClick={handleClickAddTeamMember}
              disabled={formProcessing}
            >
              {formProcessing ? "Adding" : "Add"}
            </Button>
          </div>
        </div>
      )}
      {teamMembers.length > 0 ? (
        <div className="mt-4">
          <div className="flex flex-auto flex-col border-t">
            {teamMembers.map((item) => (
              <div
                className="flex flex-col p-2 border-b sm:flex-row sm:items-center xl:py-4"
                key={item.user_id}
              >
                <div className="flex items-center">
                  <div>
                    <div>{item.login_name}</div>
                    <div className="text-secondary text-md">{item.email}</div>
                  </div>
                </div>
                <div className="mt-4 flex items-center sm:ml-auto sm:mt-0">
                  {item.login_type !== "primary" && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleClickDeleteTeamMember(item.user_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}
