// 1. React Imports:
import { useState } from "react";

// 2. Third-Party Library Imports:
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Switch,
} from "@mui/material";

// 3. Custom Hooks:

// 4. Component Imports:
import { LocationManagement } from "../../components/customers/LocationManagement";

// 5. Stylesheets:

// 6. Utility Functions or Constants:
// import { log } from "../../utils/logging";

// 7. Assets:
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SubjectIcon from "@mui/icons-material/Subject";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export function AdminUpdateAdmin({
  admin,
  closeDataDrawer,
  updateAccount,
  deleteAccount,
  addLocation,
  removeLocation,
  handleUpdateLocations,
}) {
  /*--------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);
  const [isActive, setIsActive] = useState(
    admin.status === "active" ? true : false
  );

  /*--------------------------------------
		Derived Data and Functions
	-------------------------------------*/

  /*--------------------------------------
    	Utility Functions
    -------------------------------------*/

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const timePart = date.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
    });
    return `${datePart} at ${timePart}`;
  };

  /*--------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickCloseDrawer = () => {
    closeDataDrawer();
  };

  const handleClickDialogueOpen = () => {
    setDialogueOpen(true);
  };

  const handleDialogueClose = () => {
    setDialogueOpen(false);
  };

  const handleClickUpdateAccountStatus = async () => {
    const previousStatus = isActive; // Save the current status
    const newStatus = !isActive; // Toggle status

    const data = {
      id: admin.account_id,
      data: {
        status: newStatus ? "active" : "inactive",
      },
    };

    try {
      setFormProcessing(true);

      const response = await updateAccount(data);
      if (response) {
        setIsActive(newStatus);
      } else {
        setIsActive(previousStatus);
        // log("Failed to update account status", response);
      }
    } catch (error) {
      setIsActive(previousStatus);
    } finally {
      setFormProcessing(false); // Stop form processing
    }
  };

  const handleClickDeleteAccount = () => {
    const data = { id: admin.account_id };
    deleteAccount(data);
    setDialogueOpen(false);
  };

  /*--------------------------------------
		Effects
	-------------------------------------*/

  /*--------------------------------------
		Async Functions
	-------------------------------------*/
  //console.log("details", admin);
  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">
              {admin && admin.account_name ? admin.account_name : "No name"}
            </div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={handleClickCloseDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 pt-0 sm:p-12 sm:pt-0">
        <div className="w-full max-w-3xl border-t">
          <form noValidate>
            <div className="mt-8 flex flex-col space-y-8">
              <div className="flex sm:items-center">
                <EmailOutlinedIcon className="mat-icon notranslate mat-icon-no-color" />
                <div className="ml-6 leading-6">
                  {admin && admin?.primary_user_email ? admin.primary_user_email : "No email address"}
                </div>
              </div>
              <div className="flex sm:items-center">
                <CalendarMonthIcon className="mat-icon notranslate mat-icon-no-color" />
                <div className="ml-6 leading-6">
                  {admin && admin.created_at
                    ? formatDate(admin.created_at)
                    : "No date"}
                </div>
              </div>
              {admin && admin.description && (
                <div className="flex sm:items-center">
                  <SubjectIcon className="mat-icon notranslate mat-icon-no-color" />
                  <div className="ml-6 leading-6">{admin.description}</div>
                </div>
              )}
              <div className="border-t"></div>
              <div className="flex items-center justify-between">
                <div className="flex-auto">
                  <div className="font-medium leading-6">Is active</div>
                  <div className="text-secondary text-md">
                    Controls whether an admin/internal user can log in, allowing you to
                    temporarily disable access without deleting the account.
                  </div>
                </div>
                <Switch
                  className="ml-4"
                  checked={isActive}
                  onChange={handleClickUpdateAccountStatus}
                />
              </div>
              {admin.account_type === "internal" && (
                <LocationManagement
                  account_id={admin.account_id}
                  account_locations={admin.account_locations || []}
                  addLocation={addLocation}
                  removeLocation={removeLocation}
                  updateLocations={handleUpdateLocations}
                  isInternalUser={true}
                />
              )}
              <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                <Button
                  className="mat-mdc-tooltip-trigger mdc-button mat-mdc-button mat-warn mat-mdc-button-base"
                  onClick={handleClickDialogueOpen}
                  disabled={formProcessing}
                >
                  Delete
                </Button>
                <Button
                  className="mat-mdc-tooltip-trigger ml-auto mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                  onClick={handleClickCloseDrawer}
                  disabled={formProcessing}
                >
                  Cancel
                </Button>
                <Button
                  className="mat-mdc-tooltip-trigger ml-4 mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                  variant="contained"
                  disableElevation
                  disabled={formProcessing}
                >
                  {formProcessing ? "Saving" : "Save"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={dialogueOpen}
        onClose={handleDialogueClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="flex flex-auto flex-col items-center p-8 pb-6 sm:flex-row sm:items-start sm:pb-8">
            <div className="flex h-10 w-10 flex-0 items-center justify-center rounded-full sm:mr-4 bg-warn-100 text-warn-600 dark:bg-warn-600 dark:text-warn-50">
              <WarningAmberIcon />
            </div>
            <div className="mt-4 flex flex-col items-center space-y-1 text-center sm:mt-0 sm:items-start sm:pr-8 sm:text-left">
              <div className="text-xl font-medium leading-6">
                Delete Administrator
              </div>
              <div className="text-secondary ng-star-inserted">
                Are you sure you want to delete this administrator? This action
                cannot be undone!
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="bg-gray-50 dark:bg-black dark:bg-opacity-10">
          <Button onClick={handleDialogueClose}>Cancel</Button>
          <Button
            className="mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-warn mat-mdc-button-base"
            onClick={handleClickDeleteAccount}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
