import React, { useEffect, useState, useCallback } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { LoadScript } from "@react-google-maps/api";
import { GoogleMapsLoader } from "./GoogleMapsLoader";

import { addDays, format, subDays } from "date-fns";
import { ContainersTable } from "./ContainersTable";
import { DepositDailyBarChart } from "./DepositDailyBarChart";
import { MaterialPieChart } from "./MaterialPieChart";
import { EnvironmentalStats } from "./EnvironmentalStats";
import { req } from "../../utils/api";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {StatsMap}  from "./StatsMap";

export function AnalyticsReports({ role }) {
  const baseUrl = role === "admin" ? "admin" : "customer";
  const [totalUnits, setTotalUnits] = useState(0);
  const [tabValue, setTabValue] = useState(1);
  const [materialStats, setMaterialStats] = useState([]);
  const [startDate, setStartDate] = useState(subDays(subDays(new Date(), 7), 30));
  const [endDate, setEndDate] = useState(subDays(new Date(), 7));
  const [anchorEl, setAnchorEl] = useState(null);
  const maxDate = subDays(new Date(), 7);

  const fetchData = useCallback(async () => {
    let formattedStart = encodeURIComponent(
      startDate.toISOString().split("T")[0]
    );
    let formattedEnd = encodeURIComponent(endDate.toISOString().split("T")[0]);
    const stats = await req(
      "GET",
      `${baseUrl}/get_deposits_by_material_type?startDate=${formattedStart}&endDate=${formattedEnd}`
    );
    const units = calculateTotalUnits(stats);
    setTotalUnits(units);
    setMaterialStats(stats);
  }, [baseUrl, startDate, endDate]);

  useEffect(() => {
    fetchData();
    document.title = "Analytics Admin Reports";
  }, [fetchData]);

  const calculateTotalUnits = (stats) => {
    try {
      return stats.reduce(
        (total, stat) => total + parseInt(stat.total_units),
        0
      );
    } catch (e) {
      return 0;
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelect = (ranges) => {
    const { startDate: newStartDate, endDate: newEndDate } = ranges.selection;
    const adjustedEndDate = newEndDate > maxDate ? maxDate : newEndDate;

    setStartDate(newStartDate);
    setEndDate(adjustedEndDate);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  return (
    <GoogleMapsLoader>
      <Box className="w-full relative" sx={{ p: 0 }}>
        <Box
          className="flex flex-auto flex-col w-full relative"
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            p: 0,
            boxShadow: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item sx={{textAlign: "center"}} xs={12}>
            The data available is until 7 days before the current day
            </Grid>
          <Grid item sx={{textAlign: "center"}} xs={6}>
            FILTER PROPERTIES
          </Grid>
            <Grid item xs={6}>
              <Button 
                variant="text" 
                fullWidth 
                onClick={handleOpenPopover}
                startIcon={<CalendarTodayIcon />}
              >
                {`${format(startDate, "MMM dd, yyyy")} - ${format(endDate, "MMM dd, yyyy")}`}
                
              </Button>
            </Grid>
          </Grid>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              maxDate={maxDate}
            />
          </Popover>

          <TabContext value={tabValue}>
            <Box sx={{ width: "100%" }}>
              <TabList
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="Stats tabs"
                variant="fullWidth"
                centered
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab label="Site Performance" value={0} />
                <Tab label="Environmental Impact" value={1} />
                <Tab label="Material Types" value={2} />
                <Tab label="Daily Stats" value={3} />
                <Tab label="Charity Donations" value={4} />
                {role === "customer" && <Tab label="Map" value={5} />}
              </TabList>

              <TabPanel value={0} index={0}>
                <ContainersTable 
                  role={role} 
                  startDate={startDate} 
                  endDate={endDate} 
                />
              </TabPanel>
              <TabPanel value={1} index={1}>
                <EnvironmentalStats totalUnits={totalUnits} />
              </TabPanel>
              <TabPanel value={2} index={2}>
                <MaterialPieChart materialStats={materialStats} />
              </TabPanel>
              <TabPanel value={3} index={3}>
                <DepositDailyBarChart materialStats={materialStats} />
              </TabPanel>
              <TabPanel value={4} index={4}>
                <h3>Charity Donations</h3>
                <p>
                  Coming soon.
                </p>
              </TabPanel>
              {role === "customer" && (
                <TabPanel value={5} index={5}>
                  <StatsMap 
                    role="customer" 
                    startDate={startDate} 
                    endDate={endDate} 
                  />
                </TabPanel>
              )}
            </Box>
          </TabContext>
        </Box>
      </Box>
    </GoogleMapsLoader>
  );
}
