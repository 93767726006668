// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:
import AddLinkIcon from "@mui/icons-material/AddLink";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export function AccountManagement({
  location,
  linked_accounts,
  createLinkedAccount,
  deleteLinkedAccount,
  updateLinkedAccounts,
}) {
  /*-------------------------------------
    State Variables and Hooks
  -------------------------------------*/
  const [linkedAccounts, setLinkedAccounts] = useState([]);

  const [showLinkAccount, setShowLinkAccount] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [searchAccounts, setSearchAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openRelationshipDialog, setOpenRelationshipDialog] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState("");


  /*-------------------------------------
      Derived Data and Functions
  -------------------------------------*/

  const getChipColor = (relationship) => {
    switch (relationship) {
      case "Site Contact":
        return "#FF8A80"; // Lighter red
      case "Property Manager":
        return "#B9F6CA"; // Lighter green
      case "LGA Contact":
        return "#82B1FF"; // Lighter blue
      case "Redemption Partner":
        return "#FFD180"; // Lighter orange
      default:
        return "#E0E0E0"; // Light grey (default)
    }
  };


  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleToggleLinkAccount = () => {
    setShowLinkAccount((prevState) => !prevState);
  };
  
  const handleAccountSearch = async (searchTerm) => {
    if (searchTerm && accounts.length > 0) {
      setSearchAccounts(
        accounts.filter((item) =>
          item.account_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setSearchAccounts([]);
    }
  };

  const handleAccountSelect = (event, newValue) => {
    if (!newValue) return false;

    setSelectedAccount(newValue);
    setOpenRelationshipDialog(true);
  };

  const handleLinkAccount = async () => {
    if (!selectedAccount || !selectedRelationship) {
      return false;
    }

    const postData = {
      account_id: selectedAccount.account_id,
      location_id: location.location_id,
      relationship: selectedRelationship,
    };

    const response = await createLinkedAccount(postData);

    if (response) {
      const result = response.newRecord;
      const newAccount = {
        account_location_id: result.account_location_id,
        account_id: result.account_id,
        account_name: result.account_name,
        location_relationship: result.location_relationship,
      };

      // Update the state with the new account
      const updatedAccounts = [...linkedAccounts, newAccount];
      updateLinkedAccounts(updatedAccounts);

      setOpenRelationshipDialog(false);
      setSelectedAccount(null);
      setSelectedRelationship("");
      handleToggleLinkAccount();
    }
  };

  const handleClickRemoveLinkedAccount = async (accountToDelete) => {
    
    const data = {
      account_id: accountToDelete.account_id,
      location_id: location.location_id,
    }

    const response = await deleteLinkedAccount(data);
   
    if (response) {
      updateLinkedAccounts(
        linkedAccounts.filter(
          (item) =>
            item.account_location_id !== accountToDelete.account_location_id
        )
      );
    }
  };


  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    console.log("linked_accounts:", linked_accounts);
    setLinkedAccounts(linked_accounts);
    getAccounts();
  }, [linked_accounts]);


  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const getAccounts = async () => {
    try {
      const response = await req("get", "admin/get_accounts?type=customer");
      if (response.success) {
        setAccounts(response.data.rows);
      } else {
        throw new Error(response?.message || "An unknown error occurred.");
      }
    } catch (error) {
      console.error("Failed to read account locations:", error);
    }
  }

  return (
    <>
      <div className="mx-auto flex w-full max-w-3xl items-center justify-between pt-10">
        <div className="text-2xl font-bold">Linked Accounts</div>
        <div className="flex items-center ml-auto">
          <Button
            className="mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
            onClick={handleToggleLinkAccount}
            startIcon={showLinkAccount ? null : <AddLinkIcon fontSize="small" />}
          >
            {showLinkAccount ? "Cancel" : "Link Account"}
          </Button>
        </div>
      </div>
      {showLinkAccount && (
      <div
        id="linkAccount"
        className="mx-auto flex w-full flex-col max-w-3xl items-end justify-between mt-3"
      >
        <div className="w-full">
          <label htmlFor="new_linked_account">Link a new account</label>
          <Autocomplete
            options={searchAccounts}
            getOptionLabel={(option) => option.account_name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Start typing a name..."
                variant="outlined"
              />
            )}
            onInputChange={(event, newInputValue) =>
              handleAccountSearch(newInputValue)
            }
            onChange={handleAccountSelect}
            fullWidth
          />
        </div>
      </div>
      )}
      {linked_accounts.length > 0 && (
        <div className="mt-4 flex flex-row flex-wrap gap-2">
          {linked_accounts.map((item) => {
            const chipColor = getChipColor(item.location_relationship);
            return (
              <Chip
                key={item.location_id}
                icon={<LocationOnIcon />}
                variant="outlined"
                label={`${item.account_name} (${item.location_relationship})`}
                onDelete={() => handleClickRemoveLinkedAccount(item)}
                sx={{
                  backgroundColor: chipColor,
                  color: "#000000",
                  "& .MuiChip-deleteIcon": {
                    color: "#000000",
                    "&:hover": { color: "#FF0000" },
                  },
                  fontWeight: 500,
                  padding: "4px 0",
                }}
              />
            );
          })}
        </div>
      )}
      <Dialog
        open={openRelationshipDialog}
        onClose={() => setOpenRelationshipDialog(false)}
      >
        <DialogTitle>Select Relationship Type</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedRelationship}
            onChange={(e) => setSelectedRelationship(e.target.value)}
          >
            {[
              "Site Contact",
              "Property Manager",
              "LGA Contact",
              "Redemption Partner",
            ].map((type) => (
              <FormControlLabel
                key={type}
                value={type}
                control={<Radio />}
                label={type}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRelationshipDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleLinkAccount} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
