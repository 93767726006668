import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, List, ListItem, ListItemText, Divider, Box, CircularProgress } from "@mui/material";
import { req } from "utils/api";
import { Bar } from "react-chartjs-2";
import { BarElement } from "chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export default function LoginsStats() {
  const [loginData, setLoginData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    //get last 30 days logins stats start date - 30 days yyyy-mm-dd
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date();
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];
    req(
      "GET",
      `admin/get_logins_stats?startDate=${startDateString}&endDate=${endDateString}`
    ).then((res) => {
      if(!res.data) {
        setLoginData([]);
      } else if (res.data.length > 0) {
        setLoginData(res.data);
        setDateRange(res.dateRange);
        processChartData(res.data);
      }
      setIsLoading(false);
    });
  }, []);

  const processChartData = (data) => {
    const startDate = new Date(data[0].login_time);
    const endDate = new Date(data[data.length - 1].login_time);
    const daysDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
    
    let grouping = 'daily';
    if (daysDifference > 60) {
      grouping = 'weekly';
    }

    const dateRange = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      dateRange.push(d.toISOString().split('T')[0]);
    }

    const loginsByPeriod = {};
    data.forEach((login) => {
      const date = new Date(login.login_time);
      let key;
      if (grouping === 'daily') {
        key = date.toISOString().split('T')[0];
      } else {
        // For weekly, use the Monday of each week as the key
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        key = new Date(date.setDate(diff)).toISOString().split('T')[0];
      }
      loginsByPeriod[key] = (loginsByPeriod[key] || 0) + 1;
    });

    const labels = Object.keys(loginsByPeriod).sort();
    const values = labels.map(label => loginsByPeriod[label]);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: grouping === 'daily' ? 'Logins per day' : 'Logins per week',
          data: values,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  const getUniqueUsers = () => {
    return new Set(loginData.map((login) => login.user_id)).size;
  };

  const getUniqueAccounts = () => {
    return new Set(loginData.map((login) => login.account_id)).size;
  };

  const NoDataMessage = () => (
    <Box textAlign="center" py={2}>
      <Typography variant="h6">No login data available for the period:</Typography>
      {dateRange && (
        <Typography variant="body1">
          {new Date(dateRange.startDate).toLocaleDateString()} - {new Date(dateRange.endDate).toLocaleDateString()}
        </Typography>
      )}
    </Box>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper elevation={1} style={{ padding: '20px', height: '340px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          {isLoading ? (
            <CircularProgress style={{ marginTop: '20px' }} />
          ) : loginData && loginData.length > 0 ? (
            chartData && (
              <Bar
                data={chartData}
                options={{
                  //no legend
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
                height={300}
                style={{ maxHeight: '300px' }}
              />
            )
          ) : (
            <NoDataMessage />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={1} style={{ padding: '20px', height: '340px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="flex-start" height="100%">
              <CircularProgress style={{ marginTop: '20px' }} />
            </Box>
          ) : loginData && loginData.length > 0 ? (
            <>
              <Typography variant="h5" gutterBottom>Recent Logins</Typography>
              <List>
                {loginData &&
                  loginData.slice(0, 5).map((login, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={`${new Date(login.login_time).toLocaleString()}`}
                          secondary={`User ID: ${login.user_id}, Account: ${login.account_name}`}
                        />
                      </ListItem>
                      {index < 4 && <Divider />}
                    </React.Fragment>
                  ))}
              </List>
              <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>Additional Stats</Typography>
              {loginData && (
                <Grid container spacing={2} textAlign="center">
                  <Grid item xs={4}>
                    <Box>
                      <Typography variant="h4">{loginData.length}</Typography>
                      <Typography variant="body2">Total Logins</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography variant="h4">{getUniqueUsers()}</Typography>
                      <Typography variant="body2">Unique Users</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography variant="h4">{getUniqueAccounts()}</Typography>
                      <Typography variant="body2">Unique Accounts</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="h4">{loginData[0].account_name}</Typography>
                      <Typography variant="body2">Most Active Account</Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <NoDataMessage />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
