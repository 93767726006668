import "./assets/css/index.css";

// import Home from "./pages/Home";
// import Template from "./pages/Template";
//import { Register } from "./pages/auth/Register";
import { AuthLayout } from "./layouts/AuthLayout";
import { AuthProvider } from "./contexts/AuthContext";
import { Register } from "./components/auth/SignUp";
import { Login } from "./components/auth/SignIn";
import { Forgot } from "./components/auth/Forgot";

// Admin
import { AdminLayout } from "./layouts/AdminLayout";
import { AdminCustomers } from "./pages/admin/Customers";
import { AdminDashboard } from "./pages/admin/Dashboard";
import { AdminLocations } from "./pages/admin/Locations";
import { AdminSettings } from "./pages/admin/Settings";
import { AdminAdministrators } from "./pages/admin/Administrators";
import { AnalyticsReports  } from  'components/analytics/AnalyticsReports'
import { AdminAnalyticsMaps } from "./pages/admin/AnalyticsMaps";
import { FileManager } from "./pages/admin/FileManager";
import { Log } from "./pages/admin/Log";
import { InternalUsers } from "./pages/admin/InternalUsers";

// App for Accounts
import { ToastProvider } from "./contexts/ToastContext";
import { CustomerLayout } from "./layouts/CustomerLayout";
import { CustomerHome } from "./pages/customer/Home";
import { CustomerSettings } from "./pages/customer/Settings";
import { PasswordReset } from "./components/auth/PasswordReset";
// Routes

import { Unauthorized } from "./routes/Unauthorized";
import ProtectedRoute from "./routes/ProtectedRoute";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./routes/PublicRoute";

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <Routes>
          {/* public routes */}
          <Route element={<PublicRoute />}>
            <Route element={<AuthLayout />}>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route path="unauthorized" element={<Unauthorized />} />
            </Route>
          </Route>

          {/* protected routes - admin */}
          <Route element={<ProtectedRoute />}>
              <Route path="/admin" element={<AdminLayout />}>
              <Route path="/admin/internal-users" element={<InternalUsers />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="log" element={<Log />} />
              <Route path="customers" element={<AdminCustomers />} />
              <Route path="customers/:action" element={<AdminCustomers />} />
              <Route path="customers/:action/:id" element={<AdminCustomers />} />
              <Route path="locations" element={<AdminLocations />} />
              {/* not sure we will  have a machines page
			  <Route path="machines/" element={<AdminMachines />} />
			  <Route path="machines/:action" element={<AdminMachines />} />
              <Route path="machines/:action/:id" element={<AdminMachines />} />
              */}
			  <Route path="administrators/" element={<AdminAdministrators />} />
			  <Route path="administrators/:action" element={<AdminAdministrators />} />
              <Route path="administrators/:action/:id" element={<AdminAdministrators />} />
			  <Route path="analytics/reports" element={<AnalyticsReports role={"admin"} />} />
			  <Route path="analytics/maps" element={<AdminAnalyticsMaps />} />
			  <Route path="file-manager" element={<FileManager />} />
              <Route path="settings" element={<AdminSettings />} />
            </Route>
            {/* <Route path="/app" element={<CustomerLayout />}>
              <Route path="" element={<AppHome />} />
            </Route> */}
          </Route>
          {/* private routes - customers */}
          <Route element={<ProtectedRoute />}>
            <Route path="/customer" element={<CustomerLayout />}>
              <Route path="home" element={<CustomerHome />} />
			        <Route path="settings" element={<CustomerSettings />} />
            </Route>
          </Route>

          {/* 404 */}
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
