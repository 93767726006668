// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@mui/material";

// 3. Custom Hooks:
import { useAuth } from "contexts/AuthContext";

// 4. Component Imports:
import { AnalyticsReports } from "components/analytics/AnalyticsReports";

// 6. Utility Functions or Constants:
import { req } from "utils/api";

// 7. Assets:

// --------------------------------------------------------------

export function CustomerHome() {
  /*-------------------------------------
		State Variables and Hooks
	-------------------------------------*/
  const { user } = useAuth();
  const [account, setaccount] = useState(null);

  /*-------------------------------------
		Derived Data and Functions
	-------------------------------------*/
  /*-------------------------------------
    	Utility Functions
  -------------------------------------*/
  /*-------------------------------------
		Event Handlers
	-------------------------------------*/
  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    if (user) {
      fetchaccount();
    }
  }, [user]);

  /*-------------------------------------
		Async Functions
	-------------------------------------*/

  const fetchaccount = async () => {
    try {
      const response = await req("get", "customer/get_account");
      setaccount(response.data);
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  // --------------------------------------------------------------

  if (!user || !account) {
    return <div>Loading...</div>;
  }
  console.log("Account", account);
  // --------------------------------------------------------------

  return (
    <div id="home" className="flex flex-auto flex-col relative">
      <div className="flex w-full min-w-0 flex-col sm:absolute sm:inset-0 sm:overflow-hidden">
        <div className="flex-auto relative sm:overflow-hidden sm:h-full">
          <div className="relative h-full overflow-auto">
            <div className="absolute bg-blue-gradient w-full -z-1 h-60"></div>
            <div className="flex flex-auto w-full justify-center sm:p-6 md:p-8">
              <div className="flex w-full flex-auto flex-col max-w-6xl">
                <div className="text-white sm:py-6 md:py-8">
                  <h1 className="text-5xl font-extrabold leading-none">
                    Welcome {account.login_name || ""}
                  </h1>
                  <p className="mt-2">
                    <strong>User profile number</strong>{" "}
                    {account.user_id || "123456789"}
                  </p>
                </div>
               
                
                    <div className="flex w-full flex-auto flex-col bg-white sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
                      <div className="flex flex-col">
                        <AnalyticsReports role="customer" />
                      </div>
                    </div>
                
                  
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// --------------------------------------------------------------

function HomeTeamMembers({ account }) {
  const { secondary_logins } = account; // Destructure secondary_logins from account object

  return (
    <div className="flex w-full flex-auto flex-col bg-white sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
      <div className="flex flex-col p-4">
        <div className="text-2xl font-extrabold leading-none">Team Members</div>
        <div className="flex flex-col mt-4">
          <TableContainer>
            <Table>
              <TableBody>
                {secondary_logins?.length > 0 ? (
                  secondary_logins.map((login, index) => (
                    <TableRow key={index}>
                      <TableCell className="pl-0">{login.login_name}</TableCell>
                      <TableCell className="pr-0 capitalize" align="right">
                        {login.login_type}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No team members available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="flex flex-col mt-4">
          <Link to="/customer/settings?n=team" className="text-md">
            View more Team Members ...
          </Link>
        </div>
      </div>
    </div>
  );
}
